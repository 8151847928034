import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

const TopBlog = () => {
  return (
    <div>

<Helmet>
        <title>   Blog - Fluffy Touch - Save More, Care Better</title>
        <meta
          name="description"
          content="Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful as they groom your pets in the comfort of your home"
        />
      </Helmet>
      
        <div className="s-blog-col-2">
            <div>
              <h1 className="s-blog-col-2-head">Fluffy Touch - Top Blog</h1>
              
              <div className="s-blog-col-2-list">
                <h2 className="s-blog-col-2-list-heading">Which Dog is Best for Your Home in India?</h2>
                <div className="s-blog-col-2-list-text">
                Adding a furry friend to your home is a joyous decision, but it also comes with responsibilities and considerations. In a country 
                
                <Link title="top-blog"  to="/blog/best-dog-for-home" className="s-blog-col-2-list-link"> read more...</Link>
                </div>
              </div>
              

              <div className="s-blog-col-2-list">
                <h2 className="s-blog-col-2-list-heading">Top 20 Most Popular Dog Breeds in India (2019)</h2>
                <div className="s-blog-col-2-list-text">Dogs hold a special place in the hearts of millions of people across India. They are not just pets; they are cherished members of the family
                <Link title="top-blog"  to="/blog/top-popular-dog-india" className="s-blog-col-2-list-link"> read more...</Link>
                </div>
              </div>
              

              <div className="s-blog-col-2-list">
                <div className="s-blog-col-2-list-heading">Which Dog Breeds are known for Their Friendliness?</div>
                <div className="s-blog-col-2-list-text">Dogs are often referred to as man's best friend, and for good reason. Their unwavering loyalty, affectionate nature, and innate ability to form 
                <Link title="top-blog"  to="/blog/friendliness-dog-breeds" className="s-blog-col-2-list-link"> read more...</Link>
                </div>
              </div>
              

              {/* <div className="s-blog-col-2-list">
                <div className="s-blog-col-2-list-heading"></div>
                <div className="s-blog-col-2-list-text"> 
                <Link title="top-blog"  to="" className="s-blog-col-2-list-link"> read more...</Link>
                </div>
              </div> */}
              

              
            </div>
          </div>

    </div>
  )
}

export default TopBlog