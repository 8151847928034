import React from 'react'
import Navbar from '../components/Navbar'
import BreadCrumb from '../components/BreadCrumb'
import GroomingPackageComponent from '../components/GroomingPackageComponent'
import Footer from '../components/Footer'

import { Helmet } from "react-helmet";


const GroomingPage = () => {
  return (
    <div>
      
    <Helmet>
    <title>Fluffy Touch: Pet Care Services | Pet Grooming and Boarding</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy. Dog & Cat grooming starts @599 only.
        "
        />
      </Helmet>

        <Navbar/>
        {/* <BreadCrumb name="Pet Grooming" title="Reasonable Pet Grooming And Boarding Price"/> */}
        <BreadCrumb name="Pet Grooming" title="Reasonable Pet Grooming Price"/>
        <GroomingPackageComponent/>
        <Footer/>
    </div>
  )
}

export default GroomingPage