import React from 'react'
import Navbar from '../components/Navbar'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer'
import GalleryPageComponent from '../components/GalleryPageComponent'

import { Helmet } from "react-helmet";

const GalleryPage = () => {
  return (
    <div>

<Helmet>
        <title>Fluffy Touch - Gallery : Pet Grooming and Boarding Images </title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful as they groom your pets in the comfort of your home.
        "
        />
      </Helmet>
      
        <Navbar/>
        <BreadCrumb name="Gallery" title="Fluffy Touch Gallery"/>
<GalleryPageComponent/>
        <Footer/>
        
    </div>
  )
}

export default GalleryPage