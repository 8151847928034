import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ReasonBehindLaunching from '../components/ReasonBehindLaunching'
import OurMission from '../components/OurMission'
import OurLocation from '../components/OurLocation'
import AboutUsComponent from '../components/AboutUsComponent'
import BreadCrumb from '../components/BreadCrumb'

import { Helmet } from "react-helmet";

const AboutPage = () => {
  // useEffect(()=>{
  //   document.title = "hello"
  // })
  return (
    <div>

<Helmet>
        <title>About Fluffy Touch-Pet Grooming Service at Home in Delhi NCR</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy. Our groomers take utmost care in the comfort of your home.
        "
        />
      </Helmet>
      
        <Navbar/>
        <BreadCrumb name="About Us"/>
        <AboutUsComponent/>
        <OurMission/>
        <ReasonBehindLaunching/>
        <OurLocation/>
        <Footer/>
    </div>
  )
}

export default AboutPage