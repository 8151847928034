import React from "react";

const GalleryPageComponent = () => {
  return (
    <div>
     
     <div className="gallery">
        <div>
        <h2 className="testimonial-component-heading">Our <span>Gallery</span> </h2>

          <div className="gallery-grid-1">
            <div className="gallery-col gallery-img-1"></div>
            <div className="gallery-col gallery-img-2"></div>
          </div>

          <div className="gallery-grid-2">
            <div className="gallery-col gallery-img-3"></div>
            <div className="gallery-col gallery-img-4"></div>
            <div className="gallery-col gallery-img-5"></div>
            <div className="gallery-col gallery-img-6"></div>
            <div className="gallery-col gallery-img-7"></div>
          </div>


          <div className="gallery-grid-3">
            <div className="gallery-col gallery-img-8"></div>
            <div className="gallery-col gallery-img-9"></div>
            <div className="gallery-col gallery-img-10"></div>
            <div className="gallery-col gallery-img-11"></div>
            <div className="gallery-col gallery-img-12"></div>
          </div>


          <div className="gallery-grid-4">
            <div className="gallery-col gallery-img-13"></div>
            <div className="gallery-col gallery-img-14"></div>
            <div className="gallery-col gallery-img-15"></div>
          </div>


          <div className="gallery-grid-3">
            <div className="gallery-col gallery-img-16"></div>
            <div className="gallery-col gallery-img-17"></div>
            <div className="gallery-col gallery-img-18"></div>
            <div className="gallery-col gallery-img-19"></div>
            <div className="gallery-col gallery-img-20"></div>
          </div>



          <div className="gallery-grid-2">
            <div className="gallery-col gallery-img-21"></div>
            <div className="gallery-col gallery-img-22"></div>
            <div className="gallery-col gallery-img-23"></div>
            <div className="gallery-col gallery-img-24"></div>
            <div className="gallery-col gallery-img-25"></div>
          </div>





        </div>
      </div>
     
     
    </div>
  );
};

export default GalleryPageComponent;
