import React, { useState } from 'react'
import { TypeAnimation } from "react-type-animation";

// import GroomingPackageComponent from './GroomingPackageComponent'
// import ExploreGroomingService from './ExploreGroomingService'
import OurLocation from './OurLocation'
// import OtherComponent from './OtherComponent'
import Footer from './Footer'
import headImage from  "../media/images/Pet-Grooming-For-Cts.png"


// grooming package --------------------------------------------------------------------------------

import GroomingFormComponent from './form/GroomingFormComponent'
import BookingForm from './form/BookingForm'


// exploreGrooming ----------------------------------------------------------------------------------
import bath from "../media/images/pet-bath.jpg";
import mats from "../media/images/mats-removal.jpg";
import groom from "../media/images/pet-grooming.jpg";
import nails from "../media/images/pet-nail.jpg";
import haircut from "../media/images/grooming-haircut.jpg";
import insects from "../media/images/insects.jpg";
import massage from "../media/images/pet-massage.jpg";


// exploreGrooming ----------------------------------------------------------------------------------
import serv from "../media/images/book-your-service-icon.png";
import loc from "../media/images/we-come-at-your-home-icon.png";
import groome from "../media/images/TAKE-CARE-ICON.png";
import clean from "../media/images/post-service-cleanup-icon.png";
import pay from "../media/images/PAY-ICON.png";

import BoardingFormComponent from './form/BoardingFormComponent'
import Navbar from './Navbar'
import NumberIncrement from './NumberIncrement'


import { Helmet } from "react-helmet";



const LocationPageComponent = ({location}) => {


    
    // let location = window.location.pathname.replace(/\//g,'');

    // if(location === "greater-noida"){
    //   location = "greater noida"
    // }
    // if(location === "lodhi-colony-delhi"){
    //   location = "lodhi colony, delhi"
    // }
    // if(location === "chattarpur-delhi"){
    //   location = "chattarpur, delhi"
    // }
    // if(location === "sector-36-greater-noida"){
    //   location = "sector-36, greater noida"
    // }
    // if(location === "sector-143b-noida"){
    //   location = "sector-143 b, noida"
    // }
    // if(location === "sector-99-noida"){
    //   location = "sector-99, noida"
    // }
    // // if(location === ""){
    // //   location = ""
    // // }
    // if(location === "ganga-nagar-meerut"){
    //   location = "ganga nagar, meerut"
    // }
    // if(location === "uttam-nagar-delhi"){
    //   location = "uttam nagar, delhi"
    // }
    // // if(location === ""){
    // //   location = ""
    // // }
    // if(location === "adhoiwala-dehradun"){
    //   location = "adhoiwala, dehradun"
    // }
    // if(location === "sikandra-agra"){
    //   location = "sikandra, agra"
    // }
    // if(location === "sector-43-chandigarh"){
    //   location = "sector-43, chandigarh"
    // }
    // if(location === "sector-48-faridabad"){
    //   location = "sector-48, faridabad"
    // }
    // if(location === "sector-18-noida"){
    //   location = "sector-18, noida"
    // }
    // if(location === "laxmi-nagar-delhi"){
    //   location = "laxmi nagar, delhi"
    // }
    // if(location === "fatehpur-taga-faridabad"){
    //   location = "fatehpur taga, faridabad"
    // }
    // if(location === "sector-143b-noida"){
    //   location = "sector-143 b, noida"
    // }
    // if(location === "sector-1-bisrakh-noida"){
    //   location = "sector-1, bisrakh, noida"
    // }
    // if(location === "noida-west"){
    //   location = "noida west"
    // }
    // if(location === "sector-16b-dwarka"){
    //   location = "sector-16 b, dwarka"
    // }
    
    
    // grooming package start --------------------------------------------------------------------------------
    
    const [petGroom, setPetGroom] = useState("Dog")
    const [petTraining, setPetTraining] = useState("Dog")

    const [readmore, setReadmore] = useState(false)
    

    const [openForm, setOpenForm] = useState(false)
    const [dynamicForm, setDynamicForm] = useState(false)
    const [pet, setPet] = useState("")
    const [service, setService] = useState("")
    
    // grooming package end --------------------------------------------------------------------------------
    
    // boarding package start --------------------------------------------------------------------------------

    const [boardingForm, setBoardingForm] = useState(false)
    // const [service, setService] = useState("")
    
    // boarding package end --------------------------------------------------------------------------------
    
    
    
  return (
    <div>

<Helmet>
        <title>Pet Grooming Service at Home in {location} , 599 For Dog & Cat</title>
        <meta
          name="description"
          content={
`
Pet Grooming Service at Home in ${location}: Get best pet grooming service to make your furry friend beautiful and happy. Dog & Cat grooming starts @599 only.
`
          }
        
        />
      </Helmet>
        

    {/* grooming package start   <span className='location-page-location'>{location}</span> ----------------------------------------------------------------------------------- */}
        
    <div>
        {
            openForm?
            <BookingForm setOpenForm={setOpenForm}/>
            :""
        }
        
{dynamicForm?
<GroomingFormComponent setDynamicForm={setDynamicForm} pet={pet} service={service} />
:""}

<Navbar/>

{/* header  start ----------------------------------------------------------------------------------- */}

<div className="location-page-header">
<div className="location-page-header-image"><img src={headImage} alt="" /></div>
<div className="location-page-header-content">
<div className="location-page-header-content-heading-1">
<TypeAnimation
                    sequence={[
                      "Pet Grooming At Home",
                      3500,
                      // "Cage-Free Boarding",
                      // 3500,
                      "Pet Designer Hair Cut",
                      3500,
                    ]}
                    speed={50}
                    repeat={Infinity}
                  />
</div>
<div className="location-page-header-content-heading-2">
  in <span className='location-page-location'>{location}</span>
</div>

</div>

</div>

{/* header  end   ----------------------------------------------------------------------------------- */}

<NumberIncrement/>


        <div className="package">
            <div>

                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    {/* <div className="package-head-heading"><span>Grooming</span> & <span>Boarding</span> Price in <span className='location-page-location'>{location}</span></div> */}
                    <div className="package-head-heading"><span>Grooming</span> Price in <span className='location-page-location'>{location}</span></div>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>

                <div className="package-content">
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetGroom("Dog")}}
                        className={
                            petGroom === "Dog"? "package-content-head-red":""
                        }>Dog</div>
                        <div 
                        onClick={e=>{setPetGroom("Cat")}}
                        className={
                             petGroom === "Cat"? "package-content-head-red":""
                        }>Cat</div>
                        {/* <div 
                        onClick={e=>{setPetGroom("Boarding")}}
                        className={
                             petGroom === "Boarding"? "package-content-head-red":""
                        }>Boarding</div> */}

                    </div>
{petGroom === "Dog"?
                     <div className="package-content-list">
                     <div className="package-content-list-heading"><span>Dog Grooming</span> Packages in <span className='location-page-location'>{location}</span></div>
                     
                     <div className="package-content-list-columns">

                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Face Haircut</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Minor Hair Cut</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                 <div className="package-content-list-col-point">Full Body Haircut</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Mouth Spray</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                 <div className="package-content-list-col-point">Designer Haircut</div>
                                 <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nails Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                     {
                                         readmore?"read less"
                                         :"read more"
                                     }
                                 </div>
{
 readmore?
<>
                                 <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                 <div className="package-content-list-col-point">Full Body Massage</div>
                                 <div className="package-content-list-col-point">Mouth Freshener Spray</div>
</>
:""}
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                             </div>
                         </div>

                     </div>

                 </div>

:petGroom === "Cat"?
                   
                   
<div className="package-content-list">
                        <div className="package-content-list-heading"><span>Cat Grooming</span> Packages in <span className='location-page-location'>{location}</span></div>
                        
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Ear Cleaning</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Minor Hair Cut</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                    <div className="package-content-list-col-point">Full Body Haircut</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Mouth Spray</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>

                                    <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                        {
                                            readmore?"read less"
                                            :"read more"
                                        }
                                    </div>
                                  
                                    {
    readmore?
<>
                                    <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                    <div className="package-content-list-col-point">Full Body Massage</div>
                                    <div className="package-content-list-col-point">Mouth Freshener Spray</div>
                                    </>
:""}
     
<div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                                </div>
                            </div>

                        </div>

                    </div>


:petGroom === "Boarding"?   

<div>
        {
            boardingForm?
            <BoardingFormComponent setBoardingForm={setBoardingForm} service={service}/>
            :""
        }
        <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Pet Boarding</span> Price in <span className='location-page-location'>{location}</span></div>
                        <div className="package-content-list-heading-1">Get Free Pet Full Body Grooming (worth Rs. 2,199/-) And Free Obeys Training, Upto 7-Days Boarding Service</div>

                           
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 799</s> Rs. 499/-</div>
                                    <div className="package-content-list-col-point">8 Am to 6 Pm, 1 meal included</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Day Boarding   -   Rs. 499/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Per Day</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 1,199</s> Rs. 699/-</div>
                                    <div className="package-content-list-col-point">Any day of your choice</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Per Day   -   Rs. 699/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Silver Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 6,500</s> Rs. 3,999/-</div>
                                    <div className="package-content-list-col-point">Any 7 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Silver Plan   -   Rs. 3,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Gold Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 12,000</s> Rs. 6,999/-</div>
                                    <div className="package-content-list-col-point">Any 14 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Gold Plan   -   Rs. 6,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Diamond Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 22,000</s> Rs. 11,999/-</div>
                                    <div className="package-content-list-col-point">Any 30 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Diamond Plan   -   Rs. 11,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Platinum </div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 60,000</s> Rs. 29,999/-</div>
                                    <div className="package-content-list-col-point">Any 90 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Platinum   -   Rs. 29,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Black Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 2,00,000</s> Rs. 99,999/-</div>
                                    <div className="package-content-list-col-point">Long term 1 year plan (365 days)</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Black Plan   -   Rs. 99,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                        </div>

      


                    </div>
    </div>
:""
                    
                    }



                </div>

                {/* ------------------------------------------------------------------- */}
                <div className="package-training-margin-top"></div>
                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    <div className="package-head-heading"><span>Pet</span> Designer<span> Haircut</span> Price in <span className='location-page-location'>{location}</span></div>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>


                <div className="package-content">
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetTraining("Dog")}}
                        className={
                            petTraining === "Dog"? "package-content-head-2-red":""
                        }>Dog Designer Hair Cutting</div>
                        <div 
                        onClick={e=>{setPetTraining("Cat")}}
                        className={
                            petTraining === "Cat"? "package-content-head-2-red":""
                        }>Cat Designer Hair Cutting</div>
                        {/* <div 
                        onClick={e=>{setPetTraining("Pet")}}
                        className={
                            petTraining === "Pet"? "package-content-head-2-red":""
                        }>Pets Training Packages </div> */}

                    </div>

{/* -------------------------------------------------------- */}
{
    petTraining === "Pet"?

<></>
//                     <div className="package-content-list">
//                         <div className="package-content-list-heading"><span>Pet Training</span> Packages in <span className='location-page-location'>{location}</span></div>
//                         <div className="package-content-list-columns">

//                             <div className={`package-content-list-col-img-${petTraining}-1`}>

//                             </div>


//                             <div className="package-content-list-col">
//                                 <div>
//                                     <div className="package-content-list-col-heading-1">PETS TRAINING PACKAGES</div>
//                                     <div className="package-content-list-col-point">30 Minute Session (Morning, Evening)</div>
//                                     <div className="package-content-list-col-point">Alternate Days</div>
//                                     <div className="package-content-list-col-point">Behaviour & Instructions</div>
//                                     <div className="package-content-list-col-button" onClick={e=>setOpenForm(true)}>Book Appointment</div>
//                                 </div>
//                             </div>

//                             <div className={`package-content-list-col-img-${petTraining}-2`}>

// </div>



//                         </div>
//                     </div>

                 
:
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>{petTraining}</span> Designer <span>Hair Cutting</span> Packages in <span className='location-page-location'>{location}</span> </div>
                        <div className="package-content-list-columns">

                            <div className={`package-content-list-col-img-${petTraining}`}>

                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} HAIR TRIMMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Hair Trim</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService(" HAIR TRIMMING   -   Rs. 999/-")}} >Book Appointment</div>

                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} DESIGNER HAIR CUTTING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1799 </span> Rs. 1399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService("DESIGNER HAIR CUTTING   -   Rs. 1399/-")}} >Book Appointment</div>
                                    
                                    </div>
                            </div>


                        </div>
                    </div>

}
                </div>

            </div>
        </div>
    </div>
        
    
    {/* grooming package end ----------------------------------------------------------------------------------- */}
        
        
    {/* exploreGrooming start ----------------------------------------------------------------------------------- */}
    <div>
        
        <div className="grooming-service">
      <div className="">
          <h1 className="grooming-service-heading">
            Explore Best <span>Grooming  Services </span>for Your{" "}
            <span>Pet</span> in <span className='location-page-location'>{location}</span>
          </h1>

          <div className="grooming-service-content">
            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={bath} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Pet Spa Bath
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={mats} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Knots/Mats <br /> Removal
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={groom} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Full Grooming
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={nails} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Nail Clipping
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={haircut} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Stylish Hair <br /> Cuts
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={insects} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Anti-Tick <br /> Treatment
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img src={massage} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                DeStressing
                <br /> Massage
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* exploreGrooming end ----------------------------------------------------------------------------------- */}
        
        
    {/* how ft (OtherComponent) start ----------------------------------------------------------------------------------- */}

    <div>
        
      <div className="how-ft">
        <div>
          <div className="how-ft-heading">
            How <span>Fluffy Touch</span> Works in <span className='location-page-location'>{location}</span> ?
          </div>
          <div className="how-ft-text"></div>

          <div className="how-ft-content">
            <div className="how-ft-col">
              <div className="how-ft-col-icon">
                <img src={serv} alt="" />
              </div>
              <div className="how-ft-col-heading how-ft-col-heading-1">
                Book Service
              </div>
              <div className="how-ft-col-text">
                Select the services for your pet with a preferred schedule.
              </div>
            </div>

            <div className="how-ft-col">
              <div className="how-ft-col-icon">
                <img src={loc} alt="" />
              </div>
              <div className="how-ft-col-heading how-ft-col-heading-2">
                We Come at You
              </div>
              <div className="how-ft-col-text">
                Our groomers reach at your location for your pet service.
              </div>
            </div>

            <div className="how-ft-col">
              <div className="how-ft-col-icon">
                <img src={groome} alt="" />
              </div>
              <div className="how-ft-col-heading how-ft-col-heading-3">
                Get It Groomed
              </div>
              <div className="how-ft-col-text">
                Get your pet groomed by our groomer at your location.
              </div>
            </div>

            
          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img src={clean} alt="" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-5">
            Post Service Cleanup
            </div>
            <div className="how-ft-col-text">
              {/* Get your pet groomed by our groomer at your location. */}
              After grooming, our team cleans and sanitizes grooming area
            </div>
          </div>


            <div className="how-ft-col">
              <div className="how-ft-col-icon">
                <img src={pay} alt="" />
              </div>
              <div className="how-ft-col-heading how-ft-col-heading-4">
                Relax & Payment
              </div>
              <div className="how-ft-col-text">
                Just sit back and let our groomers do their work & than pay.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    {/* how ft (OtherComponent) end ------------------------------------------------------------------------------------- */}
        
        <OurLocation/>
        <Footer/>

    </div>
  )
}

export default LocationPageComponent