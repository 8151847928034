import React from "react";
import { Link } from "react-router-dom";

const BlogBreadCrumb = ({name}) => {
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <div className="breadcrumb-heading-1">{name}</div>
          <div className="breadcrumb-heading-2">
           <Link to="/">Home </Link> &nbsp; / &nbsp;  <Link to="/blog">Blog </Link> &nbsp; / &nbsp; <span> {name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBreadCrumb;
