import React, { useState } from 'react'
import BookingForm from './form/BookingForm'
import GroomingFormComponent from './form/GroomingFormComponent'

const HairCutPagePrice = () => {

    // const [petGroom, setPetGroom] = useState("Dog")
    const [petTraining, setPetTraining] = useState("Dog")

    // const [readmore, setReadmore] = useState(false)
    

    const [openForm, setOpenForm] = useState(false)
    const [dynamicForm, setDynamicForm] = useState(false)
    const [pet, setPet] = useState("")
    const [service, setService] = useState("")
    
  return (
    <div>

<div>
        {
            openForm?
            <BookingForm setOpenForm={setOpenForm}/>
            :""
        }
        
{dynamicForm?
<GroomingFormComponent setDynamicForm={setDynamicForm} pet={pet} service={service} />
:""}
        <div className="package">
            <div>

                
                {/* ------------------------------------------------------------------- */}
                {/* <div className="package-training-margin-top"></div> */}
                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    <h2 className="package-head-heading">Our <span>Pet</span> Designer<span> Haircut</span> Services</h2>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>


                <div className="package-content">
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetTraining("Dog")}}
                        className={
                            petTraining === "Dog"? "package-content-head-2-red":""
                        }>Dog Designer Hair Cutting</div>
                        <div 
                        onClick={e=>{setPetTraining("Cat")}}
                        className={
                            petTraining === "Cat"? "package-content-head-2-red":""
                        }>Cat Designer Hair Cutting</div>
                        {/* <div 
                        onClick={e=>{setPetTraining("Pet")}}
                        className={
                            petTraining === "Pet"? "package-content-head-2-red":""
                        }>Pets Training Packages </div> */}

                    </div>

{/* -------------------------------------------------------- */}
{
    petTraining === "Pet"?
""
//                     <div className="package-content-list">
//                         <div className="package-content-list-heading"><span>Pet Training</span> Packages</div>
//                         <div className="package-content-list-columns">

//                             <div className={`package-content-list-col-img-${petTraining}-1`}>

//                             </div>


//                             <div className="package-content-list-col">
//                                 <div>
//                                     <div className="package-content-list-col-heading-1">PETS TRAINING PACKAGES</div>
//                                     <div className="package-content-list-col-point">30 Minute Session (Morning, Evening)</div>
//                                     <div className="package-content-list-col-point">Alternate Days</div>
//                                     <div className="package-content-list-col-point">Behaviour & Instructions</div>
//                                     <div className="package-content-list-col-button" onClick={e=>setOpenForm(true)}>Book Appointment</div>
//                                 </div>
//                             </div>

//                             <div className={`package-content-list-col-img-${petTraining}-2`}>

// </div>



//                         </div>
//                     </div>

                 
:
                    <div className="package-content-list">
                        <div className="package-content-list-heading"><span>{petTraining}</span> Designer <span>Hair Cutting</span> Packages</div>
                        <div className="package-content-list-columns">

                            <div className={`package-content-list-col-img-${petTraining}`}>

                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} HAIR TRIMMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Hair Trim</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService(" HAIR TRIMMING   -   Rs. 999/-")}} >Book Appointment</div>

                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">{petTraining} DESIGNER HAIR CUTTING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1799 </span> Rs. 1399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Nail Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet(petTraining); setService("DESIGNER HAIR CUTTING   -   Rs. 1399/-")}} >Book Appointment</div>
                                    
                                    </div>
                            </div>


                        </div>
                    </div>

}
                </div>
                

            </div>
        </div>
    </div>
        
    </div>
  )
}

export default HairCutPagePrice