import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({name, title}) => {
  return (
    <div>
      <div className="breadcrumb">
        <div>
          {
            title?
            <h1 className="breadcrumb-heading-1">{title}</h1>
            :
            <div className="breadcrumb-heading-1">{name}</div>
          }
          <div className="breadcrumb-heading-2">
           <Link to="/">Home </Link> &nbsp; / &nbsp; <span> {name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
