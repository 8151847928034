import React from 'react'
import LocationPageComponent from '../components/LocationPageComponent'

const LocationPage = ({location}) => {
  return (
    <div>
      <LocationPageComponent location={location}/>
      </div>
  )
}

export default LocationPage