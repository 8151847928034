import React from 'react';

const OtherPage = () => {

  // Function to set today's date as the minimum selectable date
  const setMinSelectableDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date().toISOString().slice(0, 10);
    return today;
  };

  return (
    <div>
      <label htmlFor="dateInput">Select a date:</label>
      <input
        // type="date"
        type="datetime-local"
        min={setMinSelectableDate()}
      />
    </div>
  );
};

export default OtherPage;
