import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div>
        <Navbar/>
<div className="error-page">
<div className="error-page-heading-1">404 Not Found</div>
<div className="error-page-heading-2">Your visited page not found. You may go home page.</div>
<div className="error-page-button"><Link to="/">Back to home page</Link></div>

</div>
        <Footer/>
        
    </div>
  )
}

export default ErrorPage