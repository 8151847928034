import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import { Link } from 'react-router-dom'

const AdminHomePage = () => {
  return (
    <div>
        <AdminNav/>

        <div className="admin-home">
            <div>
            <Link to="/admin/bookings"><i class="fa-solid fa-book-atlas"></i><div>Bookings</div></Link>
            {/* <Link to="/admin-inquiry"><i class="fa-solid fa-clipboard-question"></i><div>Inquiry</div></Link> */}
            <Link to="/admin/contact-us"><i class="fa-solid fa-file-contract"></i><div>Contact Us </div></Link>
            </div>
        </div>
        

    </div>
  )
}

export default AdminHomePage