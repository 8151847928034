import React, { useState } from "react";
import Slider from "react-slick";
import BookingForm from "./form/BookingForm";

const HeaderSlider = () => {

  const [openForm, setOpenForm] = useState(false)

  
  // ---------------------------------
  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  return (
    <div>
      {
            openForm?
            <BookingForm setOpenForm={setOpenForm}/>
            :""
        }
      <div>
        <Slider {...settings}>
          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-1">
              <div>
                <div className="header-slider-heading-1">Your Pet Deserves</div>
                <div className="header-slider-heading-2">The Best Care</div>
                <div className="header-slider-text">
                  No matter what pet, taking care of them is an important part
                  of letting them know you love them.
                </div>
                <div className="header-slider-button" onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-2">
              <div>
                <div className="header-slider-heading-1">
                  Qualified Personal
                </div>
                <div className="header-slider-heading-2">
                  Take care Your Pets
                </div>
                <div className="header-slider-text">
                  Our experienced professional Get Your Pet Groomed and give
                  them plenty of attention and love.
                </div>
                <div className="header-slider-button" onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-3">
              <div>

                <h1>                  
                <div className="header-slider-heading-1">
                  Get Your Pet Groomed&nbsp;
                </div>
                <div className="header-slider-heading-2">
                  With Care And Love
                </div>
                </h1>

                <div className="header-slider-text">
                  Regular grooming is a great way to care for your pet and
                  Caring for your pet is a great joy.
                </div>
                <div className="header-slider-button"  onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-1">
              <div>
                <div className="header-slider-heading-1">Your Pet Deserves</div>
                <div className="header-slider-heading-2">The Best Care</div>
                <div className="header-slider-text">
                  No matter what pet, taking care of them is an important part
                  of letting them know you love them.
                </div>
                <div className="header-slider-button" onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-2">
              <div>
                <div className="header-slider-heading-1">
                  Qualified Personal
                </div>
                <div className="header-slider-heading-2">
                  Take care Your Pets
                </div>
                <div className="header-slider-text">
                  Our experienced professional Get Your Pet Groomed and give
                  them plenty of attention and love.
                </div>
                <div className="header-slider-button" onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}

          <div>
            <div className="header-slider header-slider-3">
              <div>
                <div className="header-slider-heading-1">
                  Get Your Pet Groomed
                </div>
                <div className="header-slider-heading-2">
                  With care and love
                </div>
                <div className="header-slider-text">
                  Regular grooming is a great way to care for your pet and
                  Caring for your pet is a great joy.
                </div>
                <div className="header-slider-button"  onClick={e=>setOpenForm(true)}>Make an Appointment</div>
              </div>
            </div>
          </div>

          {/* --------------------------------- */}
        </Slider>
      </div>
    </div>
  );
};

export default HeaderSlider;
