import React from "react";
import Navbar from "../components/Navbar";
import WelcomeToFt from "../components/WelcomeToFt";
import WhyFt from "../components/WhyFt";
import HeaderSlider from "../components/HeaderSlider";
import Footer from "../components/Footer";
import TestimonialComponent from "../components/TestimonialComponent";
import GroomingPackageComponent from "../components/GroomingPackageComponent";
import NumberIncrement from "../components/NumberIncrement";
import ExploreGroomingService from "../components/ExploreGroomingService";
import OurLocation from "../components/OurLocation";
import PetBoardingComponent from "../components/PetBoardingComponent";
import HowFt from "../components/HowFt";
import ContactFixButton from "../components/ContactFixButton";

import { Helmet } from "react-helmet";
// import KeyWords from "../components/KeyWords";
import ClientSaysComponent from "../components/ClientSaysComponent";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Pet Grooming Service at Home in Delhi NCR, 599 For Dog & Cat</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy. Dog & Cat grooming starts @599 only. +91-9658-189-189
        "
        />
      </Helmet>


      <ContactFixButton />

      <Navbar />
      <HeaderSlider />
      <NumberIncrement />
      <WelcomeToFt />
      <ClientSaysComponent/>
      <GroomingPackageComponent />
      <ExploreGroomingService />
      {/* <PetBoardingComponent /> */}
      <WhyFt />
      <OurLocation />
      <HowFt /> 
      <TestimonialComponent />
      {/* <KeyWords /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
