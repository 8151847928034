import React, { useState } from "react";
import { Link } from "react-router-dom";
import blogData from "./DataBlog.json"

const BlogPageComponent = () => {

  
  const [currentPage, setCurrentPage] = useState(1)
  const recordPerPage = 15;
  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = blogData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(blogData.length / recordPerPage)
  const numbers = [...Array(npage + 1).keys()].slice(1)


  const prevPage = e=>{
  if(currentPage !== 1){
    setCurrentPage(currentPage - 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }
  }
  const changeCPage= e=>{
    setCurrentPage(e)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }
  const nextPage = e=>{
    if(currentPage !== npage){
      setCurrentPage(currentPage + 1)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    }
    
   
  }
  
  
  return (
    <div>
      <div className="blog-component">
        <div className="blog-component-col-1">
          <div className="blog-component-col-1-heading">
            Read Our <br /> <span>latest <br />blog</span>  and <span><br />articles <br /></span> and get <br /><span>updated</span>
          </div>
        </div>
        <div className="blog-component-col-2">
          <h1 className="blog-component-col-2-heading-1">
            <span>Fluffy Touch</span> latest <span>blog</span> and <span>articles</span>
          </h1>
          <div className="blog-component-col-2-heading-2">
            Welcome to <span>Fluffy Touch</span>
          </div>

          <div className="blog-component-col-2-content">



{
  records.map(e=>(
    
            <div className="blog-component-col-2-content-col">
              <h2 className="blog-component-col-2-content-col-heading-1">{e.title}</h2>
              <div className="blog-component-col-2-content-col-head">
                <div><i className="fa-solid fa-user"></i> ADMIN</div>
                <div><i className="fa-solid fa-calendar-days"></i> {e.date}</div>
              </div>
              <div className="blog-component-col-2-content-col-img">
                <img src={`${e.img}`} alt={e.title} title="blog-img" />
              </div>
              <div className="blog-component-col-2-content-col-heading-2">{e.heading}</div>
              <div className="blog-component-col-2-content-col-text">{e.text}</div>
              <div className="blog-component-col-2-content-col-button">
                <Link title={e.title} to={e.link}>Read More...</Link>
              </div>
            </div>

))
}

          </div>

          <div className="blog-component-col-2-pagination">
          <div className="blog-component-col-2-pagination-buttons ">
          <div className={`blog-component-col-2-pagination-btn ${currentPage === 1 ? 'blog-component-col-2-pagination-btn-disable' : '' }`}  onClick={prevPage}><i className="fa-solid fa-angle-left"></i></div>
          {
      numbers.map((n, i) =>(
      <div className={`blog-component-col-2-pagination-btn ${currentPage === n ? 'blog-component-col-2-pagination-btn-active' : '' }`} key={i} onClick={()=> changeCPage(n)}>{n}</div>
      ))
    }
      
         
          <div className={`blog-component-col-2-pagination-btn ${currentPage === numbers.length ? 'blog-component-col-2-pagination-btn-disable' : '' }`} onClick={nextPage}><i className="fa-solid fa-angle-right"></i></div>

          </div>


            
          </div>



        </div>
      </div>
    </div>
  );
};

export default BlogPageComponent;
