import React from "react";
import img1 from "../media/images/about.jpg";
import { Link } from "react-router-dom";

const WelcomeToFt = () => {
  return (
    <div>
      <div className="welcome-ft">
        <div>

          {/* <div className="welcome-ft-icon">
            <i className="fa-solid fa-paw"></i>
          </div> */}
          
          <h2 className="welcome-ft-heading-1">Welcome to <span>Fluffy Touch</span></h2>
          <div className="welcome-ft-heading-2">
            {/* ARE YOU LOOKING TO CARE OR GROOMING SERVICE `TO YOUR PETS? WELL, YOU ARE ON THE RIGHT PLACE. */}
          </div>
          <div className="welcome-ft-col">
            <div className="welcome-ft-col-1">
              <img title="Mobile Groomer Near Me" src={img1} alt="pet img" />
            </div>
            <div className="welcome-ft-col-2">
              <div className="welcome-ft-col-2-text">
                We offer the best grooming services for your pets. We
                are happy to groom your dogs and cat in the comfort of your
                house. We provide affordable and convenient services Our
                groomers will come with their bag of tools and use your
                washroom/balcony (As u suggested) and start their work
                instantly. Neat, clean, and efficient in a professional manner.
              </div>
              <div className="welcome-ft-col-2-points">
                <div>
                  <span>•</span>
                  <div>Team of highly trained professionals</div>
                </div>
                <div>
                  <span>•</span>
                  <div>We use best and branded products</div>
                </div>
                <div>
                  <span>•</span>
                  <div>Pet grooming services at house</div>
                </div>
                <div>
                  <span>•</span>
                  <div>We keep a regular schedule</div>
                </div>
              </div>
              <Link title="Mobile Groomer Near Me" to="/about" className="welcome-ft-col-2-button">Read More</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToFt;
