import React from 'react'

import serv from "../media/images/book-your-service-icon.png";
import loc from "../media/images/we-come-at-your-home-icon.png";
import groom from "../media/images/TAKE-CARE-ICON.png";
import clean from "../media/images/post-service-cleanup-icon.png";
import pay from "../media/images/PAY-ICON.png";

const GroomingPageFeature = () => {
  return (
    <div>
      <div>
        
      <div className="how-ft">
      <div>
        <h2 className="how-ft-heading">
          How <span>Fluffy Touch</span> Works ?
        </h2>
        <div className="how-ft-text"></div>

        <div className="how-ft-content">
          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img title="Pet Salon Near Me" src={serv} alt="Pet Salon Near Me" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-1">
              Book Service
            </div>
            <div className="how-ft-col-text">
              Select the services for your pet with a preferred schedule.
            </div>
          </div>

          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img title="Pet Spa Near Me" src={loc} alt="Same Day Dog Grooming" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-2">
              We Come at You
            </div>
            <div className="how-ft-col-text">
              Our groomers reach at your location for your pet service.
            </div>
          </div>

          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img title="Pets At Home Grooming" src={groom} alt="Dog Grooming At Home" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-3">
              Get It Groomed
            </div>
            <div className="how-ft-col-text">
              Get your pet groomed by our groomer at your location.
            </div>
          </div>

          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img title="Same Day Dog Grooming" src={clean} alt="Dog Grooming Prices" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-5">
            Post Service Cleanup
            </div>
            <div className="how-ft-col-text">
              {/* Get your pet groomed by our groomer at your location. */}
              After grooming, our team cleans and sanitizes grooming area
            </div>
          </div>

          <div className="how-ft-col">
            <div className="how-ft-col-icon">
              <img title="Dog Grooming At Home" src={pay} alt="Dog Grooming At Home" />
            </div>
            <div className="how-ft-col-heading how-ft-col-heading-4">
              Relax & Payment
            </div>
            <div className="how-ft-col-text">
              Just sit back and let our groomers do their work & than pay.
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  )
}

export default GroomingPageFeature