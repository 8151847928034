import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GroomingPageFeature from '../components/GroomingPageFeature'
import BreadCrumb from '../components/BreadCrumb'
import DogGroomingPagePrice from '../components/DogGroomingPagePrice'
import { Helmet } from 'react-helmet'

const DogGroomingPage = () => {
  return (
    <div>


<Helmet>
        <title>Pet Grooming At Home | Dog and Cat Grooming Services Near Me</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy. Dog & Cat grooming starts @599 only.
        "
        />
      </Helmet>
        
        <Navbar/>
        <BreadCrumb name="Dog Grooming" title="Best Dog Grooming Services At Home"/>

<DogGroomingPagePrice/>

        <GroomingPageFeature/>

        <Footer/>
        
    </div>
  )
}

export default DogGroomingPage