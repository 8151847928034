import React, { useState } from 'react'
import BookingForm from './form/BookingForm'
import GroomingFormComponent from './form/GroomingFormComponent'

const GroomingPagePrice = () => {

    const [petGroom, setPetGroom] = useState("Dog")

    const [readmore, setReadmore] = useState(false)
    

    const [openForm, setOpenForm] = useState(false)
    const [dynamicForm, setDynamicForm] = useState(false)
    const [pet, setPet] = useState("")
    const [service, setService] = useState("")
    
  return (
    <div>

<div>
        {
            openForm?
            <BookingForm setOpenForm={setOpenForm}/>
            :""
        }
        
{dynamicForm?
<GroomingFormComponent setDynamicForm={setDynamicForm} pet={pet} service={service} />
:""}
        <div className="package">
            <div>

                <div className="package-head">
                    {/* <div className="package-head-icon">
                        <i className="fa-solid fa-paw"></i>
                    </div> */}
                    {/* <h2 className="package-head-heading">Our <span>Grooming</span> & <span>Boarding</span> Services</h2> */}
                    <h2 className="package-head-heading">Our <span>Grooming</span> Services</h2>
                    {/* <div className="package-head-text">We Offer The Best Grooming And Boarding Services For Your Pets.</div> */}
                    <div className="package-head-text">We Offer The Best Grooming Services For Your Pets.</div>
                </div>

                <div className="package-content">
                    <div className="package-content-head-2">
                        <div 
                        onClick={e=>{setPetGroom("Dog")}}
                        className={
                            petGroom === "Dog"? "package-content-head-red":""
                        }>Dog</div>
                        <div 
                        onClick={e=>{setPetGroom("Cat")}}
                        className={
                             petGroom === "Cat"? "package-content-head-red":""
                        }>Cat</div>
                        

                    </div>
{petGroom === "Dog"?
                     <div className="package-content-list">
                     <div className="package-content-list-heading"><span>Dog Grooming</span> Packages</div>
                     
                     <div className="package-content-list-columns">

                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Face Haircut</div>
                                 <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                 <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Minor Hair Cut</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                 <div className="package-content-list-col-point">Full Body Haircut</div>
                                 <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nail's Clipping</div>
                                 <div className="package-content-list-col-point">Mouth Spray</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                             </div>
                         </div>


                         <div className="package-content-list-col">
                             <div>
                                 <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                 <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                 <div className="package-content-list-col-point">Designer Haircut</div>
                                 <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                 <div className="package-content-list-col-point">Nails Clipping</div>
                                 <div className="package-content-list-col-point">Teeth Cleaning</div>
                                 <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                 <div className="package-content-list-col-point">Combing & Brushing</div>
                                 <div className="package-content-list-col-point">Sanitary Clipping</div>
                                 <div className="package-content-list-col-point">Spritz of Perfume</div>
                                 <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                     {
                                         readmore?"read less"
                                         :"read more"
                                     }
                                 </div>
{
 readmore?
<>
                                 <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                 <div className="package-content-list-col-point">Full Body Massage</div>
                                 <div className="package-content-list-col-point">Mouth Freshener Spray</div>
</>
:""}
                                 <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Dog"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                             </div>
                         </div>

                     </div>

                 </div>

:petGroom === "Cat"?
                   
                   
<div className="package-content-list">
                        <div className="package-content-list-heading"><span>Cat Grooming</span> Packages</div>
                        
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BARK 'N BATH</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.699</span> Rs. 599 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BARK 'N BATH   -   Rs. 599/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">PAMPER PAWS</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs.899</span> Rs. 799 </div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Ear Cleaning</div>
                                    <div className="package-content-list-col-heading-3">Extra Add-ons (optional)</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning - <b>Rs 199</b></div>
                                    <div className="package-content-list-col-point">Nail's Clipping - <b>Rs 249</b></div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("PAMPER PAWS   -   Rs. 799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FUR FRESH SPA</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1199</span> Rs. 999</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FUR FRESH SPA   -   Rs. 999/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">BASIC GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 1499 </span> Rs. 1299</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Nail's Clipping</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Minor Hair Cut</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("BASIC GROOMING   -   Rs. 1,299/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">FULL BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2199</span> Rs. 1799</div>
                                    <div className="package-content-list-col-point">Full Body Haircut</div>
                                    <div className="package-content-list-col-point">Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Mouth Spray</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("FULL BODY GROOMING   -   Rs. 1,799/-")}} >Book Appointment</div>
                                </div>
                            </div>


                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">DELUXE BODY GROOMING</div>
                                    <div className="package-content-list-col-heading-2">PACKAGE CHARGE: <span> Rs. 2799</span> Rs. 2399</div>
                                    <div className="package-content-list-col-point">Designer Haircut</div>
                                    <div className="package-content-list-col-point">Medicated Bath & Blow Dry</div>
                                    <div className="package-content-list-col-point">Teeth Cleaning</div>
                                    <div className="package-content-list-col-point">Ear & Eyes Cleaning</div>
                                    <div className="package-content-list-col-point">Combing & Brushing</div>
                                    <div className="package-content-list-col-point">Sanitary Clipping</div>

                                    <div className="package-content-list-col-heading-3" onClick={e=>setReadmore(!readmore)}>
                                        {
                                            readmore?"read less"
                                            :"read more"
                                        }
                                    </div>
                                  
                                    {
    readmore?
<>
                                    <div className="package-content-list-col-point">Anti-Tick Spray</div>
                                    <div className="package-content-list-col-point">Full Body Massage</div>
                                    <div className="package-content-list-col-point">Mouth Freshener Spray</div>
                                    </>
:""}
     
<div className="package-content-list-col-button"  onClick={e=>{setDynamicForm(true); setPet("Cat"); setService("DELUXE BODY GROOMING   -   Rs. 2,399/-")}} >Book Appointment</div>
                                </div>
                            </div>

                        </div>

                    </div>



:""
                    
                    }



                </div>

                

            </div>
        </div>
    </div>
        
    </div>
  )
}

export default GroomingPagePrice