import React from 'react'

const AboutUsComponent = () => {
  return (
    <div>
        <div className="about-us-com">
            <div>
                
        <div className="about-us-com-heading-1">About Us</div>
        <div className="about-us-com-text-1">
        FluffyTouch (A Venture by Bhumu Group) is aim to groom and care your pets in Delhi NCR We have a dedicated trained and expert team to give a best services to your pet at your doorstep We always care pets like baby We focus "Pets are to be treated with care and gentle handling" Our groomers want your pet to feel fabulous as well as look it.
        </div>


        <div className="about-us-com-heading-2">What made us Start <span>Fluffy Touch?</span></div>
        <div className="about-us-com-text-2">
        The story is about a Dog lover who once had 14 dogs as his kids. But unfortunately, untimely death and conditions didn’t make this last for a long time. Owning his own personal pets wasn’t able to suffice his love for animals. He wanted to something that would keep him connected to these babies every day and every hour. His heart wept for all the little babies that roam around the streets and require proper pet care and grooming.
        </div>
        <br />
        <div className="about-us-com-text-2">
        The story is about a Dog lover who once had 14 dogs as his kids. But unfortunately, untimely death and conditions didn’t make this last for a long time. Owning his own personal pets wasn’t able to suffice his love for animals. He wanted to something that would keep him connected to these babies every day and every hour. His heart wept for all the little babies that roam around the streets and require proper pet care and grooming.
        </div>

            </div>
        </div>
    </div>
  )
}

export default AboutUsComponent