import React from "react";
import img1 from "../media/images/mission.png";

const OurMission = () => {
  return (
    <div>
      <div className="our-mission">
        <div>
          <div className="our-mission-col">
            <div className="our-mission-col-1">
              <div className="our-mission-icon">
              {/* <i className="fa-solid fa-paw"></i> */}
              </div>
              <div className="our-mission-heading-1">Our <span>Mission</span></div>
              <h1 className="our-mission-heading-2">Delivering The Best Pet Care</h1>
              <div className="our-mission-text-1">
              We aim to bring the best practices in pet care and grooming. We plan to open at least 1 salons in every city across the country. We aim to reach our target in the shortest possible time.
              </div>
              <div className="our-mission-text-2">
              Once any pet owner experiences the fresh new look, our grooming effect on their pet, they are sure to visit us again for pampering their pets. We focus "Pets are to be treated with care and gentle handling" Our groomers want your pet to feel fabulous as well as look it.
              </div>
              {/* <Link className="our-mission-button">Read More</Link> */}
            </div>

            <div className="our-mission-col-2">
                <img title="Delivering The Best Pet Care" src={img1} alt="mission img" />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
