import React from 'react'
import { Link } from 'react-router-dom'

const ReasonBehindLaunching = () => {
  return (
    <div>
        <div className="reason-bl">
            <div>
        <div className="reason-bl-icon">
        <i className="fa-solid fa-paw"></i>
        </div>
        <div className="reason-bl-heading-1">Reason Behind launching..</div>
        <div className="reason-bl-text">
        We Launched Fluffy Touch, with a spirit of providing glomming services to keep your pet healthy and charming as always. By the service fee of Fluffy Touch <b> We feed 100+ street Dogs daily in Noida if you want to be part of this social work pls join hands and donate Rs.21/- Daily (Pay Monthly) and see the live feeding.</b>
        </div>
        <Link to="tel:+91 9658-189-189" target='blank' className="reason-bl-button">Let's Talk</Link>

            </div>
        </div>
    </div>
  )
}

export default ReasonBehindLaunching