import React from "react";
import img1 from "../media/images/01.png";
import img2 from "../media/images/02.png";
import img3 from "../media/images/03.png";
import img4 from "../media/images/04.png";
import img5 from "../media/images/05.png";
import img6 from "../media/images/06.png";

const WhyFt = () => {
  return (
    <div>
      <div className="why-ft">
        <div>
          {/* <div className="why-ft-icon">
            <i className="fa-solid fa-paw"></i>
          </div> */}
          <h2 className="why-ft-heading-1">Why <span>Fluffy Touch</span></h2>
          <div className="why-ft-heading-2">
            We have dedicated team proper trained and experts in grooming and
            pet care they keep your pets happy, healthy and safe while come at
            your house to provide any service.
          </div>

          <div className="why-ft-container">
            
            <div className="why-ft-col-1">
              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">
                    Trained professionals
                  </div>
                  <div className="why-ft-col-text">
                    Our all professionals are properly trained and certified
                  </div>
                </div>
                <div className="why-ft-col">
                  <img title="Dog Bath Near Me" src={img4} alt="icon" />
                </div>
              </div>

              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">Latest Equipments</div>
                  <div className="why-ft-col-text">
                    We always use latest and modern equipments for our salon
                  </div>
                </div>
                <div className="why-ft-col">
                  <img title="Cat Nail Trimming Near Me" src={img5} alt="icon" />
                </div>
              </div>

              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">Branded products</div>
                  <div className="why-ft-col-text">
                    We love your pets like baby and use branded shampoo, oil etc
                  </div>
                </div>
                <div className="why-ft-col">
                  <img title="Mobile Cat Grooming Near Me" src={img6} alt="icon" />
                </div>
              </div>
            </div>
            <div></div>

            <div className="why-ft-col-2">
              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <img title="Best Dog Groomer Near Me" src={img1} alt="icon" />
                </div>
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">We are pet lovers</div>
                  <div className="why-ft-col-text">
                    We pamper your pets like ours, because we are also pet
                    lovers
                  </div>
                </div>
              </div>

              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <img title="Cheap Dog Grooming Near Me" src={img2} alt="icon" />
                </div>
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">Doorstep service</div>
                  <div className="why-ft-col-text">
                    Our team provide services on location as preferred by you.
                  </div>
                </div>
              </div>

              <div className="why-ft-col-point">
                <div className="why-ft-col">
                  <img title="Dog Spa Near Me" src={img3} alt="icon" />
                </div>
                <div className="why-ft-col">
                  <div className="why-ft-col-heading">24* 7 availability</div>
                  <div className="why-ft-col-text">
                    We always work and happy to give our service 24 * 7.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyFt;
