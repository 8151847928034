import React from 'react'
import Navbar from '../components/Navbar'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer'
import GroomingPagePrice from '../components/GroomingPagePrice'
import GroomingPageFeature from '../components/GroomingPageFeature'
import GroomingPageAbout from '../components/GroomingPageAbout'

import { Helmet } from "react-helmet";


const PetGroomingPage = () => {
  return (
    <div>

<Helmet>
        <title>Pet Grooming At Home | Dog and Cat Grooming Services Near Me</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy. Dog & Cat grooming starts @599 only.
        "
        />
      </Helmet>

        <Navbar/>
        <BreadCrumb name="Pet Grooming" title="Best Pet Grooming Services At Home"/>
        <GroomingPageAbout/>
        <GroomingPagePrice/>
        <GroomingPageFeature/>
        <Footer/>
    </div>
  )
}

export default PetGroomingPage