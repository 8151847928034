import React from 'react'

import bath from "../media/images/pet-bath.jpg";
import mats from "../media/images/mats-removal.jpg";
import groom from "../media/images/pet-grooming.jpg";
import nails from "../media/images/pet-nail.jpg";
import haircut from "../media/images/grooming-haircut.jpg";
import insects from "../media/images/insects.jpg";
import massage from "../media/images/pet-massage.jpg";


const ExploreGroomingService = () => {
  return (
    <div>
        
        <div className="grooming-service">
      <div className="">
          <h2 className="grooming-service-heading">
            Explore Best <span>Grooming  Services </span>for Your{" "}
            <span>Pet</span>
          </h2>

          <div className="grooming-service-content">
            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Mobile Dog Grooming" src={bath} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Pet Spa Bath
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Dog Wash Near Me" src={mats} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Knots/Mats <br /> Removal
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Dog Wash Near Me" src={groom} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Full Grooming
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Dog Wash Near Me" src={nails} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Nail Clipping
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Cat Grooming" src={haircut} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Stylish Hair <br /> Cuts
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Dog Nail Trimming Near Me" src={insects} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                Anti-Tick <br /> Treatment
              </div>
            </div>

            <div className="grooming-service-content-col">
              <div className="grooming-service-content-col-img">
                <img title="Dog Bath" src={massage} alt="service" />
              </div>
              <div className="grooming-service-content-col-text">
                DeStressing
                <br /> Massage
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExploreGroomingService