import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import BlogComponent from '../components/blog/BlogPageComponent'
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <div>
      
<Helmet>
        <title>   Blog - Fluffy Touch - Save More, Care Better</title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful as they groom your pets in the comfort of your home.
        "
        />
      </Helmet>
   
   
        <Navbar/>
        <BlogComponent/>
        <Footer/>
    </div>
  )
}

export default BlogPage;