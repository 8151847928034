import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ContactComponent from '../components/ContactComponent'
import BreadCrumb from '../components/BreadCrumb'

import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <div>
       <Helmet>
        <title>Contact Fluffy Touch : Pet Grooming Service at Home </title>
        <meta
          name="description"
          content="
          Pet Grooming Service at Home in Delhi NCR: Get best pet grooming service to make your furry friend beautiful and happy..
        "
        />
      </Helmet>
        <Navbar/>
        <BreadCrumb name="Contact Us"/>

        <ContactComponent/>
        <Footer/>
    </div>
  )
}

export default ContactPage